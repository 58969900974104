
$('#nav').affix({
    offset: {
        top: $('header').height()
    }
});

/* TESTIMONIALS */
var swiper1 = new Swiper('.swiper1', {
    pagination: '.swiper-pagination',
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    paginationClickable: true,
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: 5000,
    autoplayDisableOnInteraction: false
});


var swiper2 = new Swiper('.swiper2', {
    slidesPerView: 3,
    paginationClickable: false,
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: 3000,
    autoplayDisableOnInteraction: false,
    loop: true
});